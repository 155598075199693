module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"},{"name":"ru"}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"tags"},{"name":"slug","store":true}],"resolvers":{"MarkdownRemark":{}},"filename":"search_index.json","fetchOptions":{"credentials":"same-origin"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"108 Systems","short_name":"108 Systems","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"content/images/icon.jpg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
