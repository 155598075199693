import React from 'react'
import { Box, Markdown } from 'grommet'
import { useLocale } from '../../lib'

const ru = `
  ### Поддержать проект

  - [Пожертвовать 108.systems](https://patreon.com/108systems)
  - [Присоединиться к команде разработки 108.systems](mailto:entrypoint@108.systems)
`
const en = `
  ### Support Project

  - [Donate to 108.systems](https://patreon.com/108systems)
  - [Join 108.systems development team](mailto:entrypoint@108.systems)
`

const content = { en, ru }

export default ({ team }) => {
  const locale = useLocale()
  return (
    <Box fill flex justify='center' align='center' pad='small'>
      <Markdown>{content[locale]}</Markdown>
    </Box>
  )
}
