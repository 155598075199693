import React from 'react'
import Link from '../Link'
import Image from '../Image'

import { Box, Heading, Grid, Stack, ResponsiveContext, Text, Anchor } from 'grommet'
import { StatusGoodSmall } from 'grommet-icons'

const getStatusElem = status => {
  let statusElem = <StatusGoodSmall color='status-unknown' size='small' />
  if (['operational', 'запущен'].includes(status)) {
    statusElem = <StatusGoodSmall color='status-ok' size='small' />
  } else if (['in development', 'в разработке'].includes(status)) {
    statusElem = <StatusGoodSmall color='status-warning' size='small' />
  }
  return statusElem
}

const Category = ({ category, size }) => (
  <Link to={category.path}>
    <Box margin='xsmall' flex height='small' style={{ minHeight: '200px', minWidth: '300px', width: '1fr' }} data-testid={`${category.title}.category`}>
      <Stack fill>
        {/* <Box fill background='dark-1' /> */}
        <Box background={!category.image ? 'dark-1' : null} fill>{category.image && <Image gatsbyImage={category.image.childImageSharp} />}</Box>
        <Box fill justify='between'>
          <Box gap='xxsmall' direction='row' fill='horizontal' align='center' justify='center' background={{ color: 'black', opacity: 'strong' }}>
            {getStatusElem(category.status)}
            <Text color='light-1' size='small'>{category.status}</Text>
            {/* <Anchor size='small' href={category.site} target='blank' label={category.site} /> */}
          </Box>
          <Box fill='horizontal' align='center' justify='center' background={{ color: 'black', opacity: 'medium' }}>
            <Box pad={{ top: 'small', right: 'small', left: 'small' }}><Text weight='bold' size='large' color='light-1'>{category.title}</Text></Box>
            {/* <Heading level={3} color='light-1'>{category.title}</Heading> */}
            <Anchor size='medium' href={category.site} target='blank' label={category.site} />
          </Box>
        </Box>
      </Stack>
    </Box>
  </Link>
)

const Categories = ({ categories = [] }) => {
  const size = React.useContext(ResponsiveContext)
  return (
    <Grid
      data-testid='categories'
      align='start'
      columns={size !== 'small' && { count: 'fill', size: 'medium' }}
      gap='xsmall'
    >
      {categories.map((category, index) => (
        <Category
          key={category.title}
          category={category}
          size={size}
        />
      ))}
    </Grid>
  )
}

export default Categories
